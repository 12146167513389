@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #000b08;
  color: white;
}

.text {
  color: #4d9d98;
}